/*!

 =========================================================
 * Paper Dashboard PRO React - v1.3.0 based on Paper Dashboard 2 Pro - v2.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

 @import 'paper-dashboard/variables';
 @import 'paper-dashboard/mixins';
 
 // Plugins CSS
 @import 'paper-dashboard/plugins/plugin-bootstrap-switch';
 @import 'paper-dashboard/plugins/plugin-bootstrap-table';
 @import 'paper-dashboard/plugins/plugin-nouislider';
 @import 'paper-dashboard/plugins/plugin-animate-bootstrap-notify';
 @import 'paper-dashboard/plugins/plugin-perfect-scrollbar';
 @import 'paper-dashboard/plugins/plugin-sweetalert2';
 @import 'paper-dashboard/plugins/plugin-card-wizard';
 @import 'paper-dashboard/plugins/plugin-datetimepicker';
 @import 'paper-dashboard/plugins/plugin-bootstrap-select';
 @import 'paper-dashboard/plugins/plugin-jasny-fileupload';
 @import 'paper-dashboard/plugins/plugin-tagsinput';
 @import 'paper-dashboard/plugins/plugin-datatables.net';
 @import 'paper-dashboard/plugins/plugin-jquery.jvectormap';
 @import 'paper-dashboard/plugins/plugin-fullcalendar';
 
 // Core CSS
 @import 'paper-dashboard/nucleo-outline';
 @import 'paper-dashboard/buttons';
 @import 'paper-dashboard/social-buttons';
 @import 'paper-dashboard/animated-buttons';
 @import 'paper-dashboard/inputs';
 @import 'paper-dashboard/typography';
 @import 'paper-dashboard/misc';
 @import 'paper-dashboard/misc-extend';
 @import 'paper-dashboard/navbar';
 @import 'paper-dashboard/dropdown';
 @import 'paper-dashboard/alerts';
 @import 'paper-dashboard/images';
 @import 'paper-dashboard/tables';
 @import 'paper-dashboard/footers';
 @import 'paper-dashboard/fixed-plugin';
 
 // components
 @import 'paper-dashboard/checkboxes-radio';
 @import 'paper-dashboard/progress';
 @import 'paper-dashboard/badges';
 @import 'paper-dashboard/pagination';
 @import 'paper-dashboard/info-areas';
 @import 'paper-dashboard/pills';
 @import 'paper-dashboard/tabs';
 @import 'paper-dashboard/popups';
 @import 'paper-dashboard/modals';
 @import 'paper-dashboard/carousel';
 @import 'paper-dashboard/sidebar-and-main-panel';
 @import 'paper-dashboard/timeline';
 
 
 
 // cards
 @import 'paper-dashboard/cards';
 @import "paper-dashboard/cards/card-chart";
 @import "paper-dashboard/cards/card-map";
 @import "paper-dashboard/cards/card-user";
 
 @import "paper-dashboard/cards/card-background";
 @import "paper-dashboard/cards/card-collapse";
 @import "paper-dashboard/cards/card-contributions";
 @import "paper-dashboard/cards/card-info-area";
 @import "paper-dashboard/cards/card-lock";
 @import "paper-dashboard/cards/card-pricing";
 @import "paper-dashboard/cards/card-profile";
 @import "paper-dashboard/cards/card-signup";
 @import "paper-dashboard/cards/card-stats-mini";
 @import "paper-dashboard/cards/card-stats";
 @import "paper-dashboard/cards/card-subcategories";
 @import "paper-dashboard/cards/card-testimonials";
 @import "paper-dashboard/cards/card-tasks";
 
 
 
 // example pages and sections
 @import 'paper-dashboard/example-pages';
 @import 'paper-dashboard/sections';
 
 
 @import 'paper-dashboard/responsive';
 @import 'paper-dashboard/media-queries';
 
 // react differences
 @import "paper-dashboard/react/react-differences";
 
 
 
 @media(min-width:992px) {
     .sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper {
       height: auto;
       width: 100%;
       padding: 0;
       overflow: initial !important;
   }
   
   body .sidebar {
       height: auto;
       display: flex;
       width: 100%;
       bottom: auto;
     }
     body .main-panel {
       width: 100%;
     }
   
     body .sidebar .sidebar-wrapper {
     }
   
   body .sidebar .sidebar-wrapper>ul.nav {
     display: flex;
     margin: 0;
     width: calc(100% - 170px);
     align-self: center;
   }
   .main-panel > .navbar {
     display: none;
 }
   
 body .sidebar .sidebar-wrapper>ul.nav li>div {
     position: absolute;
     left: 0;
     top: 65px;
     background: #f9f9f9;
     z-index: 1;
     box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.161);
     padding: 5px 0;
     border-radius: 0 0px 5px 5px !important;
     overflow: hidden;
 }
   
   body .sidebar .sidebar-wrapper>ul.nav>li {
       position: relative;
       text-align: left;
       margin-left: 20px;   
     }
     body .sidebar .sidebar-wrapper>ul.nav>li>a {
         padding: 18px 0;
     }
   
   body .sidebar .sidebar-wrapper ul.nav ul.nav>li  .sidebar-normal.ml-5 {
       text-align: left;
       margin: 0 !important;
   }
   
   .sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):after,
   .sidebar .sidebar-wrapper li.active > a:not([data-toggle="collapse"]):before {
       display: none !important;
   }
   .sidebar .sidebar-wrapper {
     display: flex;
     flex-flow: row-reverse;
 }
 
 .sidebar .sidebar-wrapper .user {
     margin: 0 0 0 auto;
     padding: 15px;
 }
 
 .main-panel > .navbar {
     display: none;
 }
 
 body .sidebar .sidebar-wrapper ul.nav > li>a {
     margin: 0 0 0 0px !important;
 }
 body .sidebar .sidebar-wrapper ul.nav > li > a >p {
     display: inline-block;
     padding-right: 15px;
 }
 body .sidebar .sidebar-wrapper ul.nav > li > a > p > .caret {
     right: 0;
 }
 .sidebar .nav i {
     margin-right: 5px;
 }
 .sidebar .logo:after {
     display: none;
 }
 body .sidebar .sidebar-wrapper ul.nav li > div .nav li>a {
     padding: 8px 15px;
 }
 body .sidebar .sidebar-wrapper ul.nav li > div .nav li>a:hover {
     background: #ffffff;
 }
 .sidebar .user:after {display: none;}
 
 .sidebar .sidebar-wrapper .user>.info>a {
     max-width: 140px;
     white-space: normal;
 }
 
 .sidebar .sidebar-wrapper .user > .info {
     position: relative;
 }
 
 .sidebar .sidebar-wrapper .user > .info > div {
     right: 0;
     position: absolute;
     left: auto;
     top: 65px;
     background: #f9f9f9;
     z-index: 1;
     box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.161);
     padding: 5px 0;
     border-radius: 0 0px 5px 5px !important;
     overflow: hidden;
 }
 
 .sidebar .sidebar-wrapper .user > .info > div ul.nav>li {
     display: block;
 }
 
 .sidebar .sidebar-wrapper .user > .info > div>ul.nav {
     margin: 0;
 }
 
 .sidebar .sidebar-wrapper .user > .info > div>ul.nav span.sidebar-normal, .sidebar .sidebar-wrapper .user > .info > div>ul.nav .sidebar-mini-icon {
     display: inline-block !important;
     vertical-align: middle !important;
     line-height: 1.5 !important;
 }
 
 .sidebar .sidebar-wrapper .user > .info > div>ul.nav >li>a {
     display: inline-flex;
 }
 .sidebar .sidebar-wrapper .user>.info {
     padding-right: 0px;
 }
 .sidebar .user .info .caret {
     right: -15px;
 }
 
   }
 
   @media(max-width: 1400px) and (min-width: 992px) {
       body .sidebar .sidebar-wrapper ul.nav > li > a > i {
           font-size: 16px;
         width: 20px;
     }
     
     body .sidebar .sidebar-wrapper ul.nav > li > a {
         font-size: 12px;
     }
     .sidebar .sidebar-wrapper .user > .info > a {
         font-size: 10px;
         max-width: 110px;
     }
     body .sidebar .sidebar-wrapper ul.nav > li > a > p {
         padding-right: 10px;
     }
     body .sidebar .sidebar-wrapper > ul.nav {
         width: calc(100% - 140px);
     }
   }
 
 @media(max-width: 1199px) and (min-width: 992px) {
 body .sidebar .sidebar-wrapper ul.nav > li > a > i {
     display: none;
 }
 .sidebar .logo, .off-canvas-sidebar .logo img {
     width: 170px;
 }
 }
 @media(max-width: 991px) {
     .logo img {
         width: 170px;
         margin: 0 auto;
     }
 }